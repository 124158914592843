// extracted by mini-css-extract-plugin
export var content = "article-module--content--4-kBg";
export var header = "article-module--header--2AN75";
export var light = "article-module--light--T0qGz";
export var line = "article-module--line--543yd";
export var link = "article-module--link--MyDp7";
export var mini = "article-module--mini--XOU3I";
export var section = "article-module--section--UuqU9";
export var subsection = "article-module--subsection--Lppcb";
export var text = "article-module--text--Okh23";
export var thinline = "article-module--thinline--h25g-";
export var title = "article-module--title--ByNWE";