import React from 'react';
import { Helmet } from "react-helmet"
import Banner from '../components/banner';
import NavigationBar from '../components/navigation_bar';
// import LoadableImage from '../components/loadable_image';
import * as articleStyles from '../components/article.module.css'

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.hifunc = this.hifunc.bind(this);
    }

    render() {
        return <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Harry Zhou's Website</title>
            </Helmet>
            <Banner />
            <NavigationBar />
            <div style={{margin: 20}}>
                <h1 className={articleStyles.title}>Welcome to my website!</h1>
                <p className={articleStyles.text}>There's nothing else down here, but you can scroll up and down to see the banner's parallax animation that I made.</p>
                <div style={{height: '500px'}} />
            </div>
        </div>;
    }

    hifunc() {
        alert('Hi Jane!');
    }
}
