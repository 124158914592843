import React from 'react';
import  * as bannerStyles from './banner.module.css';
import * as loaderStyles from './loader.module.css';
import negative_forest from '../images/negative_forest.png';

export default class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.img = React.createRef();
        this.textContainer = React.createRef();
        this.handleParallax = this.handleParallax.bind(this);
        this.state = {loading: true};
        this.onImgLoad = this.onImgLoad.bind(this);
        this.checkImgLoad = this.checkImgLoad.bind(this);
    }

    render() {
        return <div className={bannerStyles.wrapper} ref={this.wrapper}>
            <img onLoad={this.onImgLoad} src={negative_forest} alt='Negative Forest' className={bannerStyles.img} ref={this.img} />
            <div className={bannerStyles.textContainer} ref={this.textContainer}>
                <h1 className={bannerStyles.text}>Harry Zhou's Website</h1>
                <h2 className={bannerStyles.text}>A personal website that I've been developing</h2>
            </div>
            {this.state.loading && <div className={loaderStyles.loader} />} {/* loader is only visible when loading is true */}
        </div>;
    }

    componentDidMount() {
        document.body.addEventListener('scroll', this.handleParallax);
        window.addEventListener('resize', this.handleParallax);
        this.checkImgLoad();
    }

    componentWillUnmount() {
        document.body.removeEventListener('scroll', this.handleParallax);
        window.removeEventListener('resize', this.handleParallax);
    }

    handleParallax() {
        let wrapperElem = this.wrapper.current;
        let imgElem = this.img.current;
        let wrapperBound = wrapperElem.getBoundingClientRect();
        let baseImagePos = wrapperElem.offsetHeight - imgElem.offsetHeight;
        let imageMoveRange = Math.min(-baseImagePos, wrapperBound.height/2);
        
        let a = -wrapperBound.top/wrapperBound.height;
        if (a <= 0) {
            imgElem.style.top = baseImagePos + 'px';
        }
        else if (a >= 1) {
            imgElem.style.top = baseImagePos + imageMoveRange  + 'px';
        }
        else {
            imgElem.style.top = baseImagePos + a*imageMoveRange  + 'px';
        }
    }

    checkImgLoad() {
        if (this.img.current.complete) {
            this.onImgLoad();
        }
    }

    onImgLoad() {
        if (this.state.loading) {
            this.handleParallax();
            this.img.current.style.animation = `${bannerStyles.imgfade} 1.5s linear 0s 1 normal forwards`;
            this.textContainer.current.style.animation = `${bannerStyles.textfade} 1s linear 0.5s 1 normal forwards`;
            this.setState({loading: false});
        }
    }
}